import React from 'react'
/* import excellent from '../images/excellence.png'
import equal from '../images/no-equal-to.png'
import amazing from '../images/suprise.png' */
/* eslint-disable jsx-a11y/no-distracting-elements */
import logo from '../images/StainMasters_png.png'

import { Link } from 'react-router-dom';
function Footer() {
  return (
    <div>
        
        <section className='lg:pt-16'>

            <div className='bg-gray-200'>
                <div className="sm:item-center bg-gray-200 sm:justify-center text-center ">

                    <h1 className='sm:tetx-2xl md:text-4xl font-bold text-navcolour'>Why Choose Us</h1>
                </div>

                <div className='lg:flex grid  lg:grid-cols-3 lg:gap-4 m-auto md:grid-cols-2 md:gap-x-2 grid-col-1 pt-8 px-2 md:px-5 '>

                      <div className="lg:px-8 px-4 col-span-1 lg:w-full md:w-1/2 sm:items-center sm:justify-center">
                        <center>{/* <img src={excellent} alt='excellence'  class='sm:items-center sm:justify-center mx-1   my-6'/> */}
                        <h5 className="md:text-2xl text-lg font-cursive font-bold tracking-tight text-gray-900 dark:text-white pt-2">
                            <p>
                            Excellent Service
                            </p>
                        </h5></center>
                        <p className="font-normal text-sm md:text-lg font-cursive text-gray-700 dark:text-gray-400 pt-4">
                            <p class='text-left'>
                            
                            The key is to set realistic customer expectations, and then not just meet them but to exceed them 
                            preferably 
                            in unexpected and helpful way.<br/><br/>
                            </p>

                            
                        </p>
                      </div>

                      <div className="lg:px-8 px-4 col-span-1 lg:w-full md:w-1/2 sm:items-center sm:justify-center">
                        <center>{/* <img src={amazing} alt='excellence'  class=" sm:items-center sm:justify-center mx-1   my-6"/> */}
                        <h5 className="md:text-2xl text-lg font-bold font-cursive tracking-tight text-gray-900 dark:text-white pt-2">
                            <p>
                            Phenomenal Delivery
                            </p>
                        </h5></center>
                        <p className="font-normal font-cursive text-sm md:text-lg text-gray-700 dark:text-gray-400 pt-4">
                            <p class='text-left'>
                            We aim to always deliver more than what is expected.<br/><br/>
                            </p>

                            
                        </p>
                      </div>

                      <div className="lg:px-8 px-4 col-span-1 lg:w-full md:w-1/2 sm:items-center sm:justify-center">
                        <center>{/* <img src={equal} alt='excellence'  class=" sm:items-center sm:justify-center mx-1   my-6"/> */}
                        <h5 className="md:text-2xl text-lg font-bold font-cursive tracking-tight text-gray-900 dark:text-white pt-2">
                            <p>
                            Unequaled Knowledge
                            </p>
                        </h5></center>
                        <p className="font-normal font-cursive text-sm md:text-lg text-gray-700 dark:text-gray-400 pt-4">
                            <p class='text-left'>
                             
                            </p>

                            
                        </p>
                      </div>

                    
        
        </div>

        <div>

<marquee behavior="scroll" direction="left "className="flex"><h1 class="mb-4 text-1xl font-extrabold font-cursive tracking-tight leading-none text-navcolour md:text-2xl lg:text-3xl">" STAINMASTERS IS AN EVER GROWING CLEANING SERVICE PROVIDER "</h1></marquee>
</div>

            </div>



        </section>
        
<footer className="bg-blue-300 dark:bg-gray-900">
    <div class="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div class="md:flex md:justify-between">
          <div class="mb-6 md:mb-0">
              <Link to="/" class="flex items-center">
                  <img src={logo} class="h-8 mr-3" alt="FlowBite Logo" />
                  <span class="self-center text-2xl font-semibold whitespace-nowrap text-navcolour dark:text-white">StainMasters</span>
              </Link>
          </div>
          <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                  <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Services</h2>
                  <ul class="text-navcolour dark:text-gray-400 font-medium">
                      <li class="mb-1">
                          <Link to="/bathroom_cleaning" class="hover:underline">Bathroom Cleaning</Link>
                      </li>
                      <li class="mb-1">
                      <Link to="/carpet_cleaning" class="hover:underline">Carpet Cleaning</Link>
                      </li>
                      <li class="mb-1">
                      <Link to="/office_cleaning" class="hover:underline">Office Cleaning</Link>
                      </li>
                      
                  </ul>
              </div>

              <div>
                  
                  <ul class="ttext-navcolour mt-11 dark:text-gray-400 font-medium">
                      
                     
                      <li class="mb-1">
                      <Link to="/deep_cleaning" class="hover:underline">Deep Cleaning</Link>
                      </li>
                      
                      <li class="mb-1">
                      <Link to="/event_cleaning" class="hover:underline">After-Event Cleaning</Link>
                      </li>
                      <li >
                      <Link to="/window_cleaning" class="hover:underline">Window Cleaning</Link>
                      </li>
                  </ul>
              </div>

              <div>
                  
                  <ul class="mt-11 text-navcolour dark:text-gray-400 font-medium">
                      
                     
                      <li class="mb-1">
                      <Link to="/kitchen_cleaning" class="hover:underline">Kitchen Cleaning</Link>
                      </li>
                      <li class="mb-1">
                      <Link to="/restaurant_cleaning" class="hover:underline">Restaurant Cleaning</Link>
                      </li>
                      <li>
                      <Link to="/tailored_cleaning" class="hover:underline">Tailored Cleaning</Link>
                      </li>

                  </ul>
              </div>
              {/* <div>
                  <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Follow us</h2>
                  <ul class="text-gray-500 dark:text-gray-400 font-medium">
                      <li class="mb-4">
                          <a href="https://github.com/themesberg/flowbite" class="hover:underline ">Github</a>
                      </li>
                      <li>
                          <a href="https://discord.gg/4eeurUVvTy" class="hover:underline">Discord</a>
                      </li>
                  </ul>
              </div> */}
              {/* <div>
                  <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
                  <ul class="text-gray-500 dark:text-gray-400 font-medium">
                      <li class="mb-4">
                          <a href="https://flowbite.com/" class="hover:underline">Privacy Policy</a>
                      </li>
                      <li>
                          <a href="https://flowbite.com/" class="hover:underline">Terms &amp; Conditions</a>
                      </li>
                  </ul>
              </div> */}
          </div>
      </div>
      <hr class="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
      <div class="sm:flex sm:items-center sm:justify-between">
          <span class="text-sm text-navcolour sm:text-center dark:text-gray-400">© 2023 
          <Link to="/" class="hover:underline">StainMastersLtd™</Link>. All Rights Reserved.
          </span>
          <span class="text-sm px-5 text-navcolour sm:text-center dark:text-gray-400"> 
          <a href="mailto:rufarochiuta@gmail.com"target="blank" class="hover:underline">Developed by Rufaro Chiuta</a>.
          </span>
          <div class="flex mt-4 space-x-5 sm:justify-center sm:mt-0">
              <a href="https://www.facebook.com/profile.php?id=61552407106811"target="blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                  <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="blue" viewBox="0 0 8 19">
                        <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                    </svg>
                  <span class="sr-only">Facebook page</span>
              </a>
              <a href="https://wa.me/447507913398"target="blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                  <svg class="w-6 h-6 bi bi-whatsapp" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="green"  viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                    </svg>
                  <span class="sr-only">Whatsapp account</span>
              </a>
              <a href="https://x.com/stainmastersltd?s=11&t=ksJmMFAEzPdupCkRpF5qIA"target="blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                  <svg class="w-6 h-6" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="black"  viewBox="0 0 512 512">
                    
                    <path fill-rule="evenodd" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" clip-rule="evenodd"/>
                </svg>
                  <span class="sr-only">Twitter handle</span>
              </a>
              <a href="https://instagram.com/stainmasters_limited?igshid=NGVhN2U2NjQ0Yg=="target="blank" class="text-gray-500 hover:text-gray-900 dark:hover:text-white">
                  <svg class="w-6 h-6 bi bi-instagram" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="red"  viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" clip-rule="evenodd"/>
                  </svg>
                  <span class="sr-only">Instagram account</span>
              </a>
              
          </div>
      </div>
    </div>
</footer>

    </div>
  )
}

export default Footer